<template>
  <section>
    <h3 :class="[style['general-text'], 'font-weight-bold', 'mb-md-5', 'mb-2']">
      Transactions
    </h3>
    <div class="h3 font-weight-bold">
      <p class="header-title pb-3 border-bottom">
        Interactions
        <img
          v-if="flag === 'edit'"
          src="../../../assets/edit-icon.png"
          alt="edit"
          @click="handleEdit"
        >
        <svg
          v-if="flag === 'save'"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          @click="handleSave"
        >
          <circle
            cx="16"
            cy="16"
            r="16"
            fill="#4A4A4A"
          />
          <path
            d="M19.6667 8H9.66667C8.74167 8 8 8.75 8 9.66667V21.3333C8 22.25 8.74167 23 9.66667 23H21.3333C22.25 23 23 22.25 23 21.3333V11.3333L19.6667 8ZM15.5 21.3333C14.1167 21.3333 13 20.2167 13 18.8333C13 17.45 14.1167 16.3333 15.5 16.3333C16.8833 16.3333 18 17.45 18 18.8333C18 20.2167 16.8833 21.3333 15.5 21.3333ZM18 13H9.66667V9.66667H18V13Z"
            fill="white"
          />
        </svg>
      </p>
    </div>
    <b-row>
      <b-col md="8">
        <b-row>
          <b-col md="8">
            <div class="font-open-sans mb-2">
              <div class="font-weight-600 d-none d-md-block">
                Category
              </div>
              <div class="font-weight-600 d-block d-md-none">
                Category wise default fee
              </div>
              <div class="bank-search-input">
                <b-input-group class="mt-1">
                  <b-form-input
                    id="video_call_title"
                    name="video_call_title"
                    type="text"
                    :class="flag === 'edit' ? 'border-0 pb-2 disabled-fee' : 'pb-2 shadow-none disabled-fee'"
                    :disabled="true"
                    value="Video Call"
                  />
                </b-input-group>
              </div>
            </div>
          </b-col>
          <b-col md="4">
            <div class="font-open-sans mb-2">
              <div class="font-weight-600 d-none d-md-block">
                Default fee
              </div>
              <div class="bank-search-input">
                <b-input-group class="mt-1">
                  <b-form-input
                    id="video_call_fee"
                    v-model="video_call_fee"
                    v-validate="{ required: true, price2: true }"
                    name="Video Call Fee"
                    type="text"
                    :class="flag === 'edit' ? 'border-0 pb-2 disabled-fee' : 'pb-2 shadow-none'"
                    :disabled="flag === 'edit' ? true : false"
                  />
                  <template
                    #prepend
                  >
                    <b-input-group-text
                      :class="flag==='edit' ? 'border-0' : 'border border-right-0'"
                    >
                      <span>
                        $
                      </span>
                    </b-input-group-text>
                  </template>
                </b-input-group>
                <span
                  id="input-1-live-video_call_fee"
                  class="valdiation_error"
                >
                  {{ veeErrors.first("Video Call Fee") }}
                </span>
              </div>
            </div>
          </b-col>
          <b-col md="8">
            <div class="font-open-sans mb-2">
              <div class="bank-search-input">
                <b-input-group class="mt-1">
                  <b-form-input
                    id="exclusive_post_title"
                    name="exclusive_post_title"
                    type="text"
                    :class="flag === 'edit' ? 'border-0 pb-2 disabled-fee' : 'pb-2 shadow-none disabled-fee'"
                    :disabled="true"
                    value="Subscription to exclusive posts"
                  />
                </b-input-group>
              </div>
            </div>
          </b-col>
          <b-col md="4">
            <div class="font-open-sans mb-2">
              <div class="bank-search-input">
                <b-input-group class="mt-1">
                  <b-form-input
                    id="exclusive_post_fee"
                    v-model="exclusive_post_fee"
                    v-validate="{ required: true, price: true }"
                    name="Exclusive Post Fee"
                    type="text"
                    :class="flag === 'edit' ? 'border-0 pb-2 disabled-fee' : 'pb-2 shadow-none'"
                    :disabled="flag === 'edit' ? true : false"
                  />
                  <template
                    #prepend
                  >
                    <b-input-group-text
                      :class="flag==='edit' ? 'border-0' : 'border border-right-0'"
                    >
                      <span>
                        $
                      </span>
                    </b-input-group-text>
                  </template>
                </b-input-group>
                <span
                  id="input-1-live-exclusive_post_fee"
                  class="valdiation_error"
                >
                  {{ veeErrors.first("Exclusive Post Fee") }}
                </span>
              </div>
            </div>
          </b-col>
          <b-col md="8">
            <div class="font-open-sans mb-2">
              <div class="bank-search-input">
                <b-input-group class="mt-1">
                  <b-form-input
                    id="live_streaming_title"
                    name="live_streaming_title"
                    type="text"
                    :class="flag === 'edit' ? 'border-0 pb-2 disabled-fee' : 'pb-2 shadow-none disabled-fee'"
                    :disabled="true"
                    value="Live Streaming"
                  />
                </b-input-group>
              </div>
            </div>
          </b-col>
          <b-col md="4">
            <div class="font-open-sans mb-2">
              <div class="bank-search-input">
                <b-input-group class="mt-1">
                  <b-form-input
                    id="live_streaming_fee"
                    v-model="live_streaming_fee"
                    v-validate="{ required: true, price2: true }"
                    name="Live Streaming Fee"
                    type="text"
                    :class="flag === 'edit' ? 'border-0 pb-2 disabled-fee' : 'pb-2 shadow-none'"
                    :disabled="flag === 'edit' ? true : false"
                  />
                  <template
                    #prepend
                  >
                    <b-input-group-text
                      :class="flag==='edit' ? 'border-0' : 'border border-right-0'"
                    >
                      <span>
                        $
                      </span>
                    </b-input-group-text>
                  </template>
                </b-input-group>
                <span
                  id="input-1-live-live_streaming_fee"
                  class="valdiation_error"
                >
                  {{ veeErrors.first("Live Streaming Fee") }}
                </span>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <store-product class="mt-5" />
    <store-payment class="mt-5" />
    <interaction-payment class="mt-5" />
    <my-payments class="mt-5" />
    <!-- <charity class="mt-5" /> -->
  </section>
</template>

<script>
import style from '../../../styles/settings.module.scss';
import StoreProduct from './ProductsStore.vue';
import StorePayment from './StorePayments.vue';
import InteractionPayment from './InteractionPayments.vue';
import MyPayments from "./MyPayments";
// import Charity from './Charity.vue';
import { updateFabSetting } from '../../../api/settings';
import { Validator } from 'vee-validate';
Validator.extend('price', {
    getMessage: "Must be a valid price.",
    validate: value => (/^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/).test(value)
});
Validator.extend('price2',{
    getMessage: "Must be a valid price.",
    validate: value => (/^\d{0,10}(\.\d{1,2})?$/).test(value)
});
export default {
    components:{StoreProduct,StorePayment, InteractionPayment, MyPayments},
    props: {
        videoCallCharge:{
            type: [ String, Number ],
            default:0
        },
        exclusivePostCharge:{
            type: [ String, Number ],
            default:0
        },
        liveStreamigCharge:{
            type: [ String, Number ],
            default:0
        },
    },
    data(){
        return {
            style,
            flag: "edit",
            video_call_fee:parseInt(this.videoCallCharge).toFixed(2),
            exclusive_post_fee:parseInt(this.exclusivePostCharge).toFixed(2),
            live_streaming_fee:parseInt(this.liveStreamigCharge).toFixed(2)
        };
    },
    methods:{
        validateState(ref) {
            if (
                this.veeFields[ref] &&
                (this.veeFields[ref].dirty || this.veeFields[ref].validated)
            ) {
                return !this.veeErrors.has(ref);
            }
            return null;
        },
        handleEdit() {
            this.flag = "save";
        },
        async handleSave(){
            this.$validator.validateAll().then(async result => {
                if (!result) {
                    this.isLoading = false;
                    return;
                }

                this.flag = 'edit';
                let ObjToSend = {
                    fab_default_video_call_fee: this.video_call_fee,
                    fab_default_exclusive_post_fee: this.exclusive_post_fee,
                    fab_default_live_streamig: this.live_streaming_fee,
                };
                let update = await updateFabSetting(ObjToSend);
                if (Object.keys(update).length > 0){
                    localStorage.setItem('videoCall', this.video_call_fee);
                    localStorage.setItem('liveStream', this.live_streaming_fee);
                    localStorage.setItem('postFees', this.exclusive_post_fee);
                }
            });
        }
    }
};
</script>
