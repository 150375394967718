<template>
  <section>
    <loading-spinner v-if="loading" />
    <add-product
      :type="popUpType"
      :showModal="showModal"
      :productData="productData"
      @handleShow="handleShow($event)"
    />
    <delete-product
      :productId="productId"
      :show="show"
      @onClose="closeDeleteProduct"
      @onDelete="handleDeleteProduct"
    />
    <div class="h3 font-weight-bold">
      <p class="header-title pb-1 border-bottom d-flex">
        <span class="mr-auto">
          Products in store
        </span>
        <b-button
          ref="button"
          variant="primary"
          class="px-2 text-uppercase ml-auto"
          pill
        >
          <span
            :class="[style['add_new_prd_store'],'px-4']"
            @click="openAddProduct(null,'add')"
          >Add New</span>
        </b-button>
      </p>
    </div>
    <section
      v-if="products.length"
      style="overflow-y: auto;height: 500px !important; overflow-x:hidden;"
    >
      <b-row
        v-for="(data) in products"
        :key="data.id"
        class="mt-2 border-bottom"
      >
        <b-col md="12">
          <b-row>
            <b-col
              lg="3"
              class="mb-2"
            >
              <b-row class="h-100">
                <b-col
                  lg="12"
                  md="6"
                >
                  <div class="font-open-sans mb-2">
                    <label class="font-weight-600">Item <span
                      v-if="data.status === 3"
                      class="badge badge-danger"
                    >Sold</span></label>
                    <div class="bank-search-input">
                      <b-input-group>
                        <b-form-input
                          :id="`item_${data.id}`"
                          name="item"
                          type="text"
                          :class="flag === 'edit' ? 'border-0 pb-2 disabled-fee' : 'pb-2 disabled-fee'"
                          :disabled="flag === 'edit' ? true : false"
                          :value="data.item"
                        />
                      </b-input-group>
                    </div>
                  </div>
                </b-col>
                <b-col
                  lg="12"
                  md="6"
                >
                  <div class="font-open-sans mt-auto">
                    <label class="font-weight-600">
                      Price
                    </label>
                    <div class="bank-search-input">
                      <b-input-group>
                        <div
                          :id="`price_${data.id}`"
                          :class="[style['product-price'],'w-100 disabled-fee']"
                        >
                          $ {{ data.price.toFixed(2) }}
                        </div>
                      </b-input-group>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <b-col
              lg="9"
              class="mb-2"
            >
              <div class="font-open-sans h-100 d-flex flex-column">
                <div class="d-flex flex-shrink-0">
                  <div class="font-weight-600">
                    Photos
                  </div>
                </div>
                <div
                  :class="[
                    style['product-photos'], 'py-3 pl-1 flex-grow-1']"
                  style="overflow-x:auto"
                >
                  <b-row
                    class="bank-search-input product-list-row ml-0 mr-1"
                    cols-sm="3"
                    cols-md="3"
                    cols-lg="5"
                  >
                    <b-col
                      v-if="data.video !== null"
                      class="mr-3 mt-2 product-video-container"
                    >
                      <video
                        fluid
                        :class="['product-video', 'ml-3 mt-1']"
                      >
                        <source :src="data.video.p_image !== undefined || data.video.p_image !== '' ? data.video.p_image : ''">
                      </video>
                      <div class="play" />
                    </b-col>
                    <template v-for="(image,index) in data.images">
                      <b-col
                        v-if="image.file_type == 'image'"
                        :key="`${image.id}${index}`"
                        :class="[style['image-container']]"
                      >
                        <!-- <img
                          v-if="image.file_type === 'image'"
                          :id="`product_${image.id}${index}`"
                          :src="image.p_image !== undefined || image.p_image !== '' ? image.p_image : placeholderImage"
                          :class="[style['image'], 'ml-3 mt-1']"
                          @error="handleError(`product_${image.id}${index}`)"
                        > -->
                        <div
                          :class="[style['image'], 'ml-3 mt-1']"
                          :style="{backgroundImage: `url(${image.p_image !== undefined || image.p_image !== '' ? image.p_image : placeholderImage}), url(${placeholderImage})`}"
                        />
                      </b-col>
                    </template>
                  </b-row>
                </div>
              </div>
            </b-col>
            <b-col md="12">
              <div class="font-open-sans mb-2">
                <div class="font-weight-600">
                  Description
                </div>
                <div class="bank-search-input">
                  <b-input-group class="mt-1">
                    <b-form-textarea
                      :id="`description_${data.id}`"
                      name="description"
                      type="text"
                      :class="flag === 'edit' ? 'border-0 pb-2 disabled-fee no-resize' : 'pb-2 no-resize'"
                      :disabled="flag === 'edit' ? true : false"
                      :value="data.description"
                      rows="4"
                    />
                  </b-input-group>
                </div>
              </div>

              <div class="text-right font-open-sans">
                <span>
                  <b-button
                    v-if="data.status !== 3"
                    variant="primary"
                    class="text-uppercase font-weight-bold px-3 py-0 mr-2 mb-3"
                    pill
                    @click="openAddProduct(data,'edit')"
                  >
                    Edit
                  </b-button>
                </span>
                <span>
                  <b-button
                    v-if="data.status !== 3"
                    variant="secondary"
                    class="text-uppercase font-weight-bold px-3 py-0 mb-3"
                    pill
                    @click="openDeleteProduct(data.id)"
                  >
                    Delete
                  </b-button>
                </span>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </section>
    <section v-else>
      <div class="font-weight-bold text-center h4">
        No product found
      </div>
    </section>
  </section>
</template>

<script>
import style from "../../../styles/settings.module.scss";
import AddProduct from '../AddProduct';
import { productList, deleteProduct } from '../../../api/settings';
import DeleteProduct from '../DeleteProduct';
import placeholderImage from '../../../assets/images/img-prod-placeholder.png';
import LoadingSpinner from '../../Common/LoadingSpinner';
export default {
    components: { AddProduct, DeleteProduct, LoadingSpinner },
    data() {
        return {
            placeholderImage,
            style,
            flag: "edit",
            counter: 0,
            products: [],
            images: [],
            showModal: false,
            productData:{},
            show: false,
            productId:"",
            loading: false,
            popUpType: 'add'
        };
    },
    async created(){
        let list = await productList();
        if (list.data.length > 0){
            list.data.map(value => {
                let objToPush = {
                    id: value.id,
                    item: value.name,
                    images: value.product_images,
                    price: value.price,
                    description:value.description,
                    video: value.product_video,
                    status: value.status,
                };
                this.products.push(objToPush);
            });
        }
    },
    methods: {
        handleEdit() {
            this.flag = "save";
        },
        handleSave() {
            this.flag = "edit";
        },
        openAddProduct(data, type){
            this.showModal = true;
            this.popUpType = type;
            if (data !== null){
                this.productData = data;
            } else if (data === null){
                this.productData = null;
            }
        },
        handleShow() {
            this.showModal = false;
        },
        openDeleteProduct(id) {
            this.productId = id;
            this.show = true;
        },
        async handleDeleteProduct(){
            this.loading = true;
            if (await deleteProduct(this.productId)){
                this.loading = false;
                setTimeout(() => {
                    window.location.href = '/settings';
                }, 1000);
            }

        },
        closeDeleteProduct (){
            this.show = false;
        },
        handleError(data){
            document.getElementById(data).src = placeholderImage;
        }
    },
};
</script>
