<template>
  <section>
    <b-modal
      id="modal-orderdetails"
      v-model="show"
      centered
      size="lg"
      header-class="border-bottom-0"
      modal-class="orderdetails-modal"
      :no-close-on-backdrop="noCloseOnBackdrop"
    >
      <LoadingSpinner v-if="loading" />
      <template
        v-slot:modal-header=""
      >
        <h4 class="font-weight-bold">
          Order Details - {{ order_no }}
        </h4>
      
        <b-button
          type="button"
          variant="default"
          class="p-0 ml-auto"
          @click="handleClose"
        >
          <icon-close
            height="24"
            width="24"
          />
        </b-button>
      </template>

      <b-container
        fluid
        class="pl-3 pr-3"
      >
        <b-table
          :items="orderItems"
          :responsive="true"
          :fields="fields"
          :class="['font-weight-light font-open-sans', style['payments-table']]"
        >
          <template #cell(item)="data">
            <a 
              v-if="data.item.item !== 'Total'"
              class="text-dark font-weight-light font-open-sans" 
              :href="`product/${data.item.id}`"
            >
              <img
                :src="`${data.item.itemImage}`"
                width="50px"
                height="50px"
              >
              <br>
              <span> {{ data.item.item }} </span>
            </a>
            <a 
              v-else
              class="text-dark font-weight-light font-open-sans" 
            >
              <span class="font-weight-bold"> {{ data.item.item }} </span>
            </a>
          </template>
          <template #cell(fabName)="data">
            <a 
              class="text-dark font-weight-light font-open-sans" 
              :href="`feed/${data.item.fabUsername}`"
            >
              {{ data.item.fabName }}
            </a>
          </template>
        </b-table>
      </b-container>

      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="default"
            pill
            class="mr-4 text-uppercase float-right"
            @click="handleClose"
          >
            Close
          </b-button>
        </div>
      </template>
    </b-modal>
  </section>
</template>

<script>
import style from "../../../styles/settings.module.scss";
import IconClose from '../../../assets/svg-import/close.svg';
import { getOrderDetails } from '../../../api/settings';
import moment from 'moment-timezone';
import LoadingSpinner from "../../Common/LoadingSpinner";

const fields = [
    { key: 'item', label: 'Product Items' },
    { key: 'date', label: 'Purchase Date' },
    { key: 'price', label: 'Price' },
    { key: 'fabName', label: 'Purchase From', tdClass: "text-capitalize"},
];

export default {
    name: 'OrderDetails',
    components: {IconClose,LoadingSpinner},
    props: {
        show: {
            type: Boolean,
            required: true,
            default: false
        },
        orderId: {
            required: true,
            type: Number,
            default:0,
        },
        totalPrice: {
            required: true,
            type: String,
        },
        orderNo: {
            required: true,
            type: String,
        },
    },
    data() {
        return {
            style,
            fields,
            loading: false,
            noCloseOnBackdrop:true,
            order_id: this.orderId,
            order_no: this.orderNo,
            total_price: this.totalPrice,
            orderItems: []
        };
    },
    watch:{
        orderId:async function(newVal){
            this.loading = true;
            let orderdetails = await getOrderDetails(newVal);
            if (Object.keys(orderdetails).length > 0){
                this.loading = false;
                this.orderItems = [];
                orderdetails.map(value => {
                    let ObjToPush = {
                        id: value.product.id,
                        item: value.product.name,
                        itemImage: value.product.product_images[0].p_thumb_image,
                        date: value.created_at !== null ? moment(value.created_at).format('MMM DD, YYYY') : '',
                        price: value.price !== null ? '$'+value.price.toFixed(2) : '$'+0,
                        fabName: value.fab.name+' '+value.fab.surname,
                        fabUsername: value.fab.username,
                        fabImage: value.fab.profile_pic_url
                    };
                    this.orderItems.push(ObjToPush);
                });
                let ObjToPush = {
                    item: "Total",
                    date: "",
                    price: this.totalPrice,
                    fabName: "",
                    fabUsername: "",
                    fabImage: ""
                };
                this.orderItems.push(ObjToPush);
            }
        },
    },
    async created(){
        this.loading = true;
        let orderdetails = await getOrderDetails(this.order_id);
        if (Object.keys(orderdetails).length > 0){
            this.orderItems = [];
            orderdetails.map(value => {
                let ObjToPush = {
                    id: value.product.id,
                    item: value.product.name,
                    itemImage: value.product.product_images[0].p_thumb_image,
                    date: value.created_at !== null ? moment(value.created_at).format('MMM DD, YYYY') : '',
                    price: value.price !== null ? '$'+value.price.toFixed(2) : '$'+0,
                    fabName: value.fab.name+' '+value.fab.surname,
                    fabUsername: value.fab.username,
                    fabImage: value.fab.profile_pic_url
                };
                this.orderItems.push(ObjToPush);
            });
        }

        let ObjToPush = {
            item: "Total",
            date: "",
            price: this.total_price,
            fabName: "",
            fabUsername: "",
            fabImage: ""
        };
        this.orderItems.push(ObjToPush);

        this.loading = false;
    },
    methods: {
        handleClose() {
            this.$emit('onClose');
        },
    }
};
</script>