var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_vm._m(0),_c('div',[_c('p',{class:[_vm.style['account-text'],'font-open-sans']},[_vm._v(" Verification "),_vm._m(1)]),_c('p',{class:[_vm.style['account-text'],'font-open-sans']},[_vm._v(" Facebook "),_vm._m(2)])]),_vm._m(3),_c('div',[_c('b-form-checkbox',{staticClass:"font-open-sans theme-checkbox custom-checkbox-success mb-4",attrs:{"id":"email_notification","name":"email_notification","value":"1","unchecked-value":"0"},on:{"change":_vm.notification},model:{value:(
        _vm.email_notification
      ),callback:function ($$v) {
        _vm.email_notification
      =$$v},expression:"\n        email_notification\n      "}},[_vm._v(" Email Notification ")]),_c('b-form-checkbox',{staticClass:"font-open-sans theme-checkbox custom-checkbox-success mb-4",attrs:{"id":"in_app_notification","name":"in_app_notification","value":"1","unchecked-value":"0"},on:{"change":_vm.notification},model:{value:(
        _vm.in_app_notification
      ),callback:function ($$v) {
        _vm.in_app_notification
      =$$v},expression:"\n        in_app_notification\n      "}},[_vm._v(" In-app Notification ")])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h3 font-weight-bold"},[_c('p',{staticClass:"header-title pb-3 border-bottom"},[_vm._v(" Account ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{attrs:{"href":"#"}},[_c('u',[_vm._v("Verify")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{attrs:{"href":"#"}},[_c('u',[_vm._v("Connect with Facebook")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h3 font-weight-bold mt-5"},[_c('p',{staticClass:"header-title pb-3 border-bottom"},[_vm._v(" Notifications ")])])}]

export { render, staticRenderFns }