<template>
  <section>
    <LoadingSpinner v-if="loading" />
    <crop
      ref="cropRef"
      :picPopup="picPopup"
      :editImage="editImage"
      @cropProfilePhoto="updateSelectedProfPic"
      @closePicPopup="resetProfilePic"
    />
    <b-container class="py-md-4">
      <b-row>
        <b-col
          md="4"
          class="text-center mt-5"
        >
          <b-row>
            <b-col
              cols="12"
              :class="[style['profile-pic-wrap'], 'mb-4']"
            >
              <div
                :class="[
                  style['profile-pic'],
                  'rounded-circle border border-white',
                ]"
                :style="{
                  backgroundImage: `url(${profile_image_url}), url(${API_IMAGE_URL})`,
                }"
              >
                <input
                  id="profile_image"
                  type="file"
                  style="display: none"
                  accept=".jpg, .jpeg, .png"
                  @change="previewFiles"
                >
                <label
                  for="profile_image"
                  class="profile-upload"
                >
                  Change profile photo</label>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              class="text-left mt-5"
            >
              <h6 class="text-uppercase">
                Upcoming Events
              </h6>
            </b-col>
            <b-col
              cols="12"
              :class="['pr-5 text-left py-3', style['empty-events']]"
            >
              <p class="font-weight-light font-open-sans">
                Your calendar is empty. Book a videocall with celebrity to
                change that
              </p>
            </b-col>
            <router-link to="/celebsearch">
              <b-button
                variant="primary"
                pill
                class="px-5 text-uppercase"
              >
                Browse Popz
              </b-button>
            </router-link>
          </b-row>
        </b-col>
        <b-col md="8">
          <h2 class="font-weight-bold mb-md-5 mb-4 mt-5 display-4">
            Settings
          </h2>
          <h3 class="font-weight-bold mb-md-5 mb-2 pb-2 border-bottom">
            Personal
          </h3>
          <b-row>
            <b-col md="6">
              <div class="font-open-sans mb-2">
                <div
                  id="name-label"
                  class="font-weight-600"
                >
                  Name
                </div>
                <div class="bank-search-input">
                  <b-input-group class="mt-1">
                    <b-form-input
                      id="name"
                      v-model="name"
                      v-validate="{ required: true }"
                      :state="validateState('name')"
                      name="name"
                      type="text"
                      :class="[
                        style['input-field'],
                        'bg-transparent',
                        'border shadow-none',
                      ]"
                    />
                  </b-input-group>
                  <span
                    id="input-1-live-agree_process_personal_data"
                    class="valdiation_error"
                  >
                    {{ veeErrors.first("name") }}
                  </span>
                </div>
              </div>
            </b-col>
            <b-col md="6">
              <div class="font-open-sans mb-2">
                <div
                  id="surname-label"
                  class="font-weight-600"
                >
                  Last Name
                </div>
                <div class="bank-search-input">
                  <b-input-group class="mt-1">
                    <b-form-input
                      id="surname"
                      v-model="surname"
                      v-validate="{ required: true }"
                      :state="validateState('surname')"
                      data-vv-as="Last Name"
                      name="surname"
                      type="text"
                      :class="[
                        style['input-field'],
                        'bg-transparent',
                        'border shadow-none',
                      ]"
                    />
                  </b-input-group>
                  <span
                    id="input-1-live-agree_process_personal_data"
                    class="valdiation_error"
                  >
                    {{ veeErrors.first("surname") }}
                  </span>
                </div>
              </div>
            </b-col>
            <b-col md="6">
              <div class="font-open-sans mb-2">
                <div
                  id="email-label"
                  class="font-weight-600"
                >
                  Email
                </div>
                <div class="bank-search-input">
                  <b-input-group class="mt-1">
                    <b-form-input
                      id="email"
                      v-model="email"
                      v-validate="{ required: true }"
                      :state="validateState('email')"
                      name="email"
                      type="email"
                      :class="[
                        style['input-field'],
                        'bg-transparent',
                        'border shadow-none',
                      ]"
                      :disabled="true"
                    />
                  </b-input-group>
                  <span
                    id="input-1-live-agree_process_personal_data"
                    class="valdiation_error"
                  >
                    {{ veeErrors.first("email") }}
                  </span>
                </div>
              </div>
            </b-col>
            <b-col md="6">
              <div class="font-open-sans mb-2">
                <div
                  id="password-label"
                  class="font-weight-600"
                >
                  Password
                </div>
                <div class="bank-search-input">
                  <b-input-group class="mt-1">
                    <b-form-input
                      id="password"
                      v-model="password"
                      v-validate="{
                        min: 6,
                        password: true,
                      }"
                      name="password"
                      type="password"
                      :class="[
                        style['input-field'],
                        'bg-transparent',
                        'border shadow-none',
                      ]"
                    />
                    <template
                      v-if="flag === 'edit'"
                      #append
                    >
                      <b-input-group-text class="border-0 h-100">
                        <span :class="style['change-text']"> Change </span>
                      </b-input-group-text>
                    </template>
                  </b-input-group>
                  <span
                    id="input-1-live-agree_process_personal_data"
                    class="valdiation_error"
                  >
                    {{ veeErrors.first("password") }}
                  </span>
                </div>
              </div>
            </b-col>
            <b-col md="6">
              <div class="font-open-sans mb-2">
                <div
                  id="birthdate-label"
                  class="font-weight-600"
                >
                  Birthdate
                </div>
                <div class="bank-search-input">
                  <b-input-group class="mt-1 d-block">
                    <datepicker
                      id="birthdate"
                      v-model="birthdate"
                      v-validate="{ required: true }"
                      placeholder="Select Birthdate"
                      :state="validateState('birthdate')"
                      aria-describedby="input-1-live-birthdate"
                      data-vv-as="Birthdate"
                      name="birthdate"
                      format="MM/dd/yyyy"
                      :bootstrap-styling="true"
                      :calendar-button="true"
                      :clear-button="true"
                      calendar-button-icon="fa fa-calendar"
                      clear-button-icon="fa fa-times"
                      :disabledDates="disabledDates"
                      :class="['bg-transparent', 'border shadow-none']"
                    />
                  </b-input-group>
                  <span
                    id="input-1-live-agree_process_personal_data"
                    class="valdiation_error"
                  >
                    {{ veeErrors.first("birthdate") }}
                  </span>
                </div>
              </div>
            </b-col>
            <b-col md="6">
              <div class="font-open-sans mb-2">
                <div
                  id="username-label"
                  class="font-weight-600"
                >
                  Username
                </div>
                <div class="bank-search-input">
                  <b-input-group class="mt-1">
                    <b-form-input
                      id="username"
                      v-model="username"
                      v-validate="{ required: true, min: 3 }"
                      :state="validateState('username')"
                      name="username"
                      type="text"
                      autocomplete="off"
                      maxlength="50"
                      :class="[
                        style['input-field'],
                        'bg-transparent',
                        'border shadow-none',
                      ]"
                      @keyup="checkUsername"
                    />
                  </b-input-group>
                  <span
                    id="input-1-live-agree_username"
                    class="valdiation_error"
                  >
                    {{ veeErrors.first("username") }}
                  </span>
                </div>
              </div>
            </b-col>
            <b-col md="6">
              <div class="font-open-sans mb-2">
                <div
                  id="address-label"
                  class="font-weight-600"
                >
                  Address
                </div>
                <div class="bank-search-input">
                  <b-input-group class="mt-1">
                    <vue-google-autocomplete
                      id="map"
                      ref="address_line"
                      v-model="address_line"
                      v-validate="{ required: true }"
                      :state="validateState('address')"
                      name="address"
                      placeholder="Select Address"
                      type="text"
                      :class="[
                        style[('input-field', 'address-input')],
                        'bg-transparent',
                        'border shadow-none',
                      ]"
                      @placechanged="getAddressData"
                    />
                    <span
                      id="input-1-live-address_line"
                      class="valdiation_error_custom"
                    >
                      {{ veeErrors.first("address_line") }}
                    </span>
                  </b-input-group>
                  <span
                    id="input-1-live-address"
                    class="valdiation_error"
                  >
                    {{ veeErrors.first("address") }}
                  </span>
                </div>
              </div>
            </b-col>
            <b-col md="6">
              <div class="font-open-sans mb-2">
                <div
                  id="country-label"
                  class="font-weight-600"
                >
                  Country
                </div>
                <div class="bank-search-input">
                  <b-input-group class="mt-1 d-block">
                    <Select2
                      id="country"
                      v-model="country"
                      v-validate="{ required: true }"
                      name="country"
                      :options="countryoptions"
                      :state="validateState('country')"
                      class="form-control w-100"
                      placeholder="Select Country"
                      @change="changeCountry"
                    />
                  </b-input-group>
                  <span
                    id="input-1-live-country"
                    class="valdiation_error"
                  >
                    {{ veeErrors.first("country") }}
                  </span>
                </div>
              </div>
            </b-col>
            <b-col md="6">
              <div class="font-open-sans mb-2">
                <div
                  id="state-label"
                  class="font-weight-600"
                >
                  State
                </div>
                <div class="bank-search-input">
                  <b-input-group class="mt-1 d-block">
                    <Select2
                      id="state"
                      v-model="state"
                      v-validate="{ required: true }"
                      name="state"
                      :options="stateoptions"
                      :state="validateState('state')"
                      class="form-control w-100"
                      placeholder="Select State"
                      @change="changeState"
                    />
                  </b-input-group>
                  <span
                    id="input-1-live-country"
                    class="valdiation_error"
                  >
                    {{ veeErrors.first("state") }}
                  </span>
                </div>
              </div>
            </b-col>
            <b-col md="6">
              <div class="font-open-sans mb-2">
                <div
                  id="city-label"
                  class="font-weight-600"
                >
                  City
                </div>
                <div class="bank-search-input">
                  <b-input-group class="mt-1 d-block">
                    <Select2
                      id="city"
                      v-model="city"
                      v-validate="{ required: true }"
                      name="city"
                      :options="cityoptions"
                      :state="validateState('city')"
                      class="form-control w-100"
                      placeholder="Select City"
                    />
                  </b-input-group>
                  <span
                    id="input-1-live-city"
                    class="valdiation_error"
                  >
                    {{ veeErrors.first("city") }}
                  </span>
                </div>
              </div>
            </b-col>
            <b-col md="6">
              <div class="font-open-sans mb-2">
                <div
                  id="timezone-label"
                  class="font-weight-600"
                >
                  Timezone
                </div>
                <div class="bank-search-input">
                  <b-input-group class="mt-1 d-block">
                    <Select2
                      id="timezone"
                      v-model="timezone"
                      v-validate="{ required: true }"
                      name="timezone"
                      :options="timezone_list"
                      :state="validateState('timezone')"
                      class="form-control w-100"
                      placeholder="Select Timezone"
                      @change="setTimezone"
                    />
                  </b-input-group>
                  <span
                    id="input-1-live-timezone"
                    class="valdiation_error"
                  >
                    {{ veeErrors.first("timezone") }}
                  </span>
                </div>
              </div>
            </b-col>
            <b-col md="6">
              <div class="font-open-sans mb-2">
                <div
                  id="zipcode-label"
                  class="font-weight-600"
                >
                  Zipcode
                </div>
                <div class="bank-search-input">
                  <b-input-group class="mt-1 d-block">
                    <b-form-input
                      id="zipcode"
                      v-model="zipcode"
                      v-validate="{ required: true }"
                      name="zipcode"
                      :state="validateState('zipcode')"
                      :class="[style['input-field'], 'form-control w-100']"
                      placeholder="Enter Zipcode"
                    />
                  </b-input-group>
                  <span
                    id="input-1-live-zipcode"
                    class="valdiation_error"
                  >
                    {{ veeErrors.first("zipcode") }}
                  </span>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-button
                variant="primary"
                pill
                class="text-uppercase px-5 my-2"
                :disabled="loading"
                @click="updateuser"
              >
                Save
              </b-button>
            </b-col>
          </b-row>
          <account-setting
            :email="email_notification"
            :text="in_app_notification"
            @handleNotifications="handleNotifications"
          />
          <payments />
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>
<script>
import style from "../../../styles/settings.module.scss";
import { Validator } from "vee-validate";
import axios from "axios";
import { API_BASE_URL, API_IMAGE_URL } from "../../../config/config";
import { APIUrlList } from "../../../js/api_url";
import { uploadToBucket, _sendResponse } from "../../../js/common-function";
import AccountSetting from "./Account";
import Payments from "./Payments";
import LoadingSpinner from "../../Common/LoadingSpinner";
import Datepicker from "vuejs-datepicker";
import {
    setCityOptions,
    setStateOptions,
    setCountryOptions,
    updateUser,
} from "../../../api/register";
import { setTimezoneOptions } from "../../../api/meeting";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import Crop from "../../../components/Common/Crop.vue";

// import VueGoogleAutocomplete from 'vue-google-autocomplete';

Validator.extend("password", {
    getMessage:
    "Password should have at least 1 lowercase,1 uppercase,1 number and 1 symbol.",
    validate: (value) =>
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/.test(value),
});

export default {
    components: {
        AccountSetting,
        Payments,
        LoadingSpinner,
        Datepicker,
        VueGoogleAutocomplete,
        Crop,
    },
    props: {
        accountDetails: {
            type: Object,
            default: () => {},
        },
        flag: {
            type: String,
            default: "edit",
            require: true,
        },
        profilePicUrl: {
            type: String,
            default: "",
            require: true,
        },
    },
    data() {
        return {
            API_IMAGE_URL,
            disabledDates: {
                from: new Date(Date.now() - 8640000),
            },
            style,
            name: "",
            surname: "",
            email: "",
            password: "",
            username: "",
            profile_pic: null,
            profile_image_url: "",
            loading: false,
            email_notification: "",
            in_app_notification: "",
            birthdate: "",
            country_list: [],
            country: "",
            state_list: [],
            state: "",
            city_list: [],
            city: "",
            timezone: "",
            timezone_list: [],
            zipcode: "",
            address_line: "",
            countryoptions: [],
            stateoptions: [],
            cityoptions: [],
            countrynames: [],
            statenames: [],
            citynames: [],
            countrycodes: [],
            customercountrycode: "",
            editImage: true,
            picPopup: false,
            profile_pic_file: "",
            is_update_profile: 0,
        };
    },
    watch: {
        accountDetails: async function(newVal) {
            this.name = newVal.name;
            this.surname = newVal.surname;
            this.email = newVal.email;
            this.username = newVal.username;
            this.profile_image_url = newVal.profile_pic_url;
            this.profile_pic = newVal.profile_pic_url;
            this.email_notification = newVal.email_notification;
            this.in_app_notification = newVal.in_app_notification;
            this.birthdate = newVal.birthdate;
            this.country =
        newVal.user_address !== null ? newVal.user_address.country_id : "";
            this.address_line =
        newVal.user_address !== null ? newVal.user_address.address_line : "";
            this.is_update_profile = newVal.is_profile_updated;

            /* if (newVal.is_profile_updated == 0) {
                _sendResponse("warning", "Please complete your profile first");
            } */
            localStorage.setItem("isProfileUpdated", newVal.is_profile_updated);
            this.loading = true;
            let allstates = await setStateOptions(this.country);
            if (allstates.states.length > 0) {
                this.loading = false;
                this.stateoptions = allstates.states;
                this.statenames = allstates.statenames;
                this.state =
          newVal.user_address !== null ? newVal.user_address.state_id : "";
            } else {
                this.loading = false;
            }

            let allcities = await setCityOptions(this.country, this.state);
            if (allcities.cities.length > 0) {
                this.loading = false;
                this.cityoptions = allcities.cities;
                this.citynames = allcities.citynames;
                this.city =
          newVal.user_address !== null ? newVal.user_address.city_id : "";
            } else {
                this.loading = false;
            }

            this.timezone =
        newVal.user_address !== null ? newVal.user_address.timezone_id : "";
            this.zipcode =
        newVal.user_address !== null ? newVal.user_address.zip_code : "";
        },
        profilePicUrl: function(newVal) {
            this.profile_image_url = newVal;
            this.profile_pic = newVal;
        },
    },
    async created() {
        let timezones = await setTimezoneOptions();
        this.timezone_list = timezones.timezones2;
        this.timezone_list_offset = timezones.offset;
        let allcountries = await setCountryOptions();
        if (allcountries.countries.length > 0) {
            this.countryoptions = allcountries.countries;
            this.countrynames = allcountries.countriesnames;
            this.countrycodes = allcountries.countriescode;
        }
    },
    methods: {
        validateState(ref) {
            if (
                this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
            ) {
                return !this.veeErrors.has(ref);
            }
            return null;
        },
        handleEdit() {
            this.flag = "save";
        },
        handleNotifications(obj) {
            this.email_notification = obj.email;
            this.in_app_notification = obj.text;
            this.updateuser(this.email_notification, this.in_app_notification);
        },
        setTimezone() {
            this.timezone_offset = this.timezone_list_offset[this.timezone];
        },
        checkUsername() {
            this.username = this.username
                .toLowerCase()
                .replace(/[^\w\s]/gi, "")
                .split(" ")
                .join("");
        },
        async updateuser(email, text) {
            this.loading = true;
            let formData = {};
            var googleAddress = document.getElementById("map").value;
            if (
                (googleAddress == "" || googleAddress == null) &&
        this.address != ""
            ) {
                this.address_line = "";
            }
            this.$validator.validateAll().then(async (result) => {
                if (!result) {
                    this.loading = false;
                    const firstField = Object.keys(this.veeErrors.collect())[0];
                    document.getElementById(firstField + "-label").scrollIntoView();

                    return;
                }
                this.is_update_profile = 1;
                formData.name = this.name;
                formData.surname = this.surname;
                formData.email = this.email;
                formData.username = this.username;
                formData.email_notification = email;
                formData.in_app_notification = text;
                formData.birthdate = this.birthdate;
                this.password !== "" && this.password !== null
                    ? (formData.password = this.password)
                    : null;
                formData.country_id = this.country;
                formData.state_id = this.state;
                formData.city_id = this.city;
                formData.zip_code = this.zipcode;
                formData.timezone_id = this.timezone;
                formData.address_line = this.address_line;
                formData.is_update_profile = this.is_update_profile;

                let updated = await updateUser(formData);
                if (updated.status) {
                    this.loading = false;
                    localStorage.setItem("fullname", this.name + " " + this.surname);
                    localStorage.setItem("isProfileUpdated", this.is_update_profile);
                    setTimeout(() => {
                        window.location.href = "/settings";
                    }, 1000);
                } else {
                    this.loading = false;
                }
            });
        },
        async previewFiles(event) {
            var checkfile = await this.profileCheck(event.target.files[0]);

            if (checkfile === false) {
                _sendResponse(
                    "error",
                    "Invalid profile picture: The profile picture must be a .jpeg, .jpg or .png file."
                );
                return false;
            }
            this.picPopup = true;
            this.profile_pic_file = event.target.files[0];
            this.profilePic = URL.createObjectURL(event.target.files[0]);
            this.$refs.cropRef.bindFile(this.profilePic);
        },
        async uploadProfilePic(pic) {
            this.loading = true;
            var profile_pic = await uploadToBucket(pic, "uploads/users/");
            var formdata = {
                profile_pic: profile_pic,
            };
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.getItem("user-token")}`;
            axios
                .post(API_BASE_URL + APIUrlList.CHANGEPROFILEPIC, formdata)
                .then((response) => {
                    this.loading = false;
                    _sendResponse("success", response.data.message);
                    this.profile_image_url = response.data.data.profile_pic_url;
                    this.profile_pic = response.data.data.profile_pic_url;
                    localStorage.setItem(
                        "user-image",
                        response.data.data.profile_pic_url
                    );
                    setTimeout(function() {
                        window.location.reload();
                    }, 500);
                })
                .catch((error) => {
                    this.loading = false;
                    // handle authentication and validation errors here
                    _sendResponse("error", error);
                });
        },
        async changeCountry(event) {
            this.loading = true;
            this.country = event;
            this.state = "";
            this.city = "";
            this.zipcode = "";
            this.stateoptions = [];
            this.statenames = [];
            this.cityoptions = [];
            this.citynames = [];
            //this.teleinput.setCountry(this.countrycodes[this.country]);

            let allstates = await setStateOptions(this.country);
            if (allstates.states.length > 0) {
                this.stateoptions = allstates.states;
                this.statenames = allstates.statenames;
            }

            this.loading = false;
        },
        async changeState(event) {
            this.loading = true;
            this.state = event;
            this.city = "";
            this.zipcode = "";
            this.cityoptions = [];
            this.citynames = [];

            let allcities = await setCityOptions(this.country, this.state);
            if (allcities.cities.length > 0) {
                this.cityoptions = allcities.cities;
                this.citynames = allcities.citynames;
            }

            this.loading = false;
        },
        getAddressData: async function(addressData, placeResultData) {
            this.loading = true;
            let street_number =
        addressData.street_number === undefined
            ? ""
            : addressData.street_number;
            let addressroute =
        addressData.route === undefined ? "" : addressData.route;
            let locality =
        addressData.locality === undefined ? "" : addressData.locality;
            let administrative_area_level_1 =
        addressData.administrative_area_level_1 === undefined
            ? ""
            : addressData.administrative_area_level_1;
            let addresscountry =
        addressData.country === undefined ? "" : addressData.country;

            this.address_line =
        street_number +
        " " +
        addressroute +
        ", " +
        locality +
        " " +
        administrative_area_level_1 +
        ", " +
        addresscountry;
            this.latitude =
        addressData.latitude === undefined ? "" : addressData.latitude;
            this.longitude =
        addressData.longitude === undefined ? "" : addressData.longitude;
            this.zipcode =
        addressData.postal_code === undefined ? "" : addressData.postal_code;

            if (addresscountry != "") {
                let placecountry = this.countrynames[
                    addresscountry.toLowerCase().trim()
                ];
                this.country = placecountry;

                let allstates = await setStateOptions(this.country);
                if (allstates.states.length > 0) {
                    this.stateoptions = allstates.states;
                    this.statenames = allstates.statenames;

                    let allcities = await setCityOptions(this.country, this.state);
                    if (allcities.cities.length > 0) {
                        this.cityoptions = allcities.cities;
                        this.citynames = allcities.citynames;
                    }
                }

                let statename = "";
                let cityname = "";
                let cityshortname = "";
                let localityshortname = "";
                if (placeResultData.address_components) {
                    placeResultData.address_components.map(async (value) => {
                        if (value.types[0] == "administrative_area_level_1") {
                            statename = value.long_name;
                        }

                        if (value.types[0] == "administrative_area_level_2") {
                            cityname = value.long_name;
                            cityshortname = value.short_name;
                        }

                        if (
                            value.types[0] == "locality" ||
              value.types[0] == "postal_town"
                        ) {
                            localityshortname = value.short_name;
                        }
                    });
                }

                if (statename != "") {
                    let placestate = this.statenames[statename.toLowerCase().trim()];
                    this.state = placestate;

                    let allcities = await setCityOptions(this.country, this.state);
                    if (allcities.cities.length > 0) {
                        this.cityoptions = allcities.cities;
                        this.citynames = allcities.citynames;

                        if (cityname != "") {
                            let placecity = this.citynames[cityname.toLowerCase().trim()];
                            this.city = placecity === undefined ? "" : placecity;

                            if (
                                this.city == "" ||
                this.city == null ||
                this.city == undefined
                            ) {
                                let placecityshort = this.citynames[
                                    cityshortname.toLowerCase().trim()
                                ];
                                //this.city = placecityshort === undefined ? "" : placecityshort;
                                let localityshort = this.citynames[
                                    localityshortname.toLowerCase().trim()
                                ];

                                let addresscity =
                  placecityshort === undefined ? "" : placecityshort;
                                this.city = addresscity;
                                let googlecityoptions = this.cityoptions;
                                //set locality name as city name if administrative_area_level_2 is not in our cities list
                                if (
                                    googlecityoptions.filter((e) => e.text === addresscity)
                                        .length == 0
                                ) {
                                    this.city = localityshort === undefined ? "" : localityshort;
                                }
                            }
                        }
                    }
                }
            } else {
                this.country = "";
                this.state = "";
                this.city = "";
                this.zipcode = "";
                this.stateoptions = [];
                this.statenames = [];
                this.cityoptions = [];
                this.citynames = [];
            }

            this.loading = false;
        },
        async updateSelectedProfPic(pic) {
            this.profile_picture = pic;
            this.picPopup = false;
            this.url = URL.createObjectURL(pic);
            this.selectedFile = this.url;

            this.profilePic = URL.createObjectURL(pic);

            this.uploadProfilePic(pic);
        },
        uploadUncropped() {
            this.uploadProfilePic(this.profile_pic_file);
        },
        async profileCheck(file) {
            var filetype = file.type;
            var fileextension = filetype.split("/").pop();
            var arr = ["jpg", "jpeg", "png"];

            var check = arr.includes(fileextension);

            return check;
        },
        resetProfilePic() {
            this.picPopup = false;
        },
    },
};
</script>
