<template>
  <section>
    <div class="h3 font-weight-bold">
      <p class="header-title pb-3 border-bottom">
        Account
      </p>
    </div>
    <div>
      <p :class="[style['account-text'],'font-open-sans']">
        Verification <a href="#"><u>Verify</u></a>
      </p>
      <p :class="[style['account-text'],'font-open-sans']">
        Facebook <a href="#"><u>Connect with Facebook</u></a>
      </p>
    </div>
    <div class="h3 font-weight-bold mt-5">
      <p class="header-title pb-3 border-bottom">
        Notifications
      </p>
    </div>
    <div>
      <b-form-checkbox
        id="email_notification"
        v-model="
          email_notification
        "
        name="email_notification"
        value="1"
        unchecked-value="0"
        class="font-open-sans theme-checkbox custom-checkbox-success mb-4"
        @change="notification"
      >
        Email Notification
      </b-form-checkbox>
      <b-form-checkbox
        id="in_app_notification"
        v-model="
          in_app_notification
        "
        name="in_app_notification"
        value="1"
        unchecked-value="0"
        class="font-open-sans theme-checkbox custom-checkbox-success mb-4"
        @change="notification"
      >
        In-app Notification
      </b-form-checkbox>
    </div>
  </section>
</template>

<script>
import style from '../../styles/settings.module.scss';
export default {
    name:'Account',
    props:{
        text:{
            type: [Number,String],
            default: 0,
        },
        email:{
            type: [Number,String], 
            default: 0
        }
    },
    data(){
        return {
            style,
            email_notification: this.email,
            in_app_notification:this.text,
        };
    },
    watch:{
        text:function(newVal){
            this.in_app_notification = newVal;
        },
        email:function(newVal){
            this.email_notification = newVal;
        }
    },
    methods:{
        notification(){
            let objToSend = {
                email: this.email_notification,
                text: this.in_app_notification
            };
            this.$emit('handleNotification', objToSend);
        }
    },
};
</script>