<template>
  <div>
    <LoadingSpinner v-if="loading" />
    <gab-setting
      v-if="account_type === '1'"
      :flag="flag"
      :profilePicUrl="profile_pic_url"
      :accountDetails="accountDetails"
    />
    <b-container
      v-else
      :class="[style['settings-wrap'], 'py-md-4 mb-5']"
    >
      <h2 class="font-weight-bold mb-md-5 mb-4 mt-5 display-4">
        Settings
      </h2>
      <h3
        :class="[style['general-text'], 'font-weight-bold', 'mb-md-5', 'mb-2']"
      >
        General
      </h3>
      <div class="h3 font-weight-bold">
        <p class="header-title pb-3 border-bottom">
          Personal
          <img
            v-if="flag === 'edit'"
            src="../assets/edit-icon.png"
            alt="edit"
            @click="handleEdit"
          >
          <svg
            v-if="flag === 'save'"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            @click="handleSave"
          >
            <circle
              cx="16"
              cy="16"
              r="16"
              fill="#4A4A4A"
            />
            <path
              d="M19.6667 8H9.66667C8.74167 8 8 8.75 8 9.66667V21.3333C8 22.25 8.74167 23 9.66667 23H21.3333C22.25 23 23 22.25 23 21.3333V11.3333L19.6667 8ZM15.5 21.3333C14.1167 21.3333 13 20.2167 13 18.8333C13 17.45 14.1167 16.3333 15.5 16.3333C16.8833 16.3333 18 17.45 18 18.8333C18 20.2167 16.8833 21.3333 15.5 21.3333ZM18 13H9.66667V9.66667H18V13Z"
              fill="white"
            />
          </svg>
        </p>
      </div>
      <b-row>
        <b-col md="6">
          <b-row>
            <b-col md="6">
              <div class="font-open-sans mb-2">
                <div class="font-weight-600">
                  Name
                </div>
                <div class="bank-search-input">
                  <b-input-group class="mt-1">
                    <b-form-input
                      id="name"
                      v-model="name"
                      v-validate="{ required: true }"
                      :state="validateState('name')"
                      name="name"
                      type="text"
                      :class="
                        flag === 'edit'
                          ? [style['input-field'], 'border-0 disabled-fee']
                          : ''
                      "
                      :disabled="flag === 'edit' ? true : false"
                      autocomplete="off"
                    />
                  </b-input-group>
                  <span
                    id="input-1-live-agree_process_personal_data"
                    class="valdiation_error"
                  >
                    {{ veeErrors.first("name") }}
                  </span>
                </div>
              </div>
            </b-col>
            <b-col md="6">
              <div class="font-open-sans mb-2">
                <div class="font-weight-600">
                  Last Name
                </div>
                <div class="bank-search-input">
                  <b-input-group class="mt-1">
                    <b-form-input
                      id="surname"
                      v-model="surname"
                      v-validate="{ required: true }"
                      :state="validateState('surname')"
                      data-vv-as="Last Name"
                      name="surname"
                      type="text"
                      :class="
                        flag === 'edit'
                          ? [style['input-field'], 'border-0 disabled-fee']
                          : ''
                      "
                      :disabled="flag === 'edit' ? true : false"
                      autocomplete="off"
                    />
                  </b-input-group>
                  <span
                    id="input-1-live-agree_process_personal_data"
                    class="valdiation_error"
                  >
                    {{ veeErrors.first("surname") }}
                  </span>
                </div>
              </div>
            </b-col>
            <b-col md="6">
              <div class="font-open-sans mb-2">
                <div class="font-weight-600">
                  Email
                </div>
                <div class="bank-search-input">
                  <b-input-group class="mt-1">
                    <b-form-input
                      id="email"
                      v-model="email"
                      v-validate="{ required: true }"
                      :state="validateState('email')"
                      name="email"
                      type="email"
                      :class="[style['input-field'], 'border-0 disabled-fee']"
                      disabled
                      autocomplete="off"
                    />
                  </b-input-group>
                  <span
                    id="input-1-live-agree_process_personal_data"
                    class="valdiation_error"
                  >
                    {{ veeErrors.first("email") }}
                  </span>
                </div>
              </div>
            </b-col>
            <b-col md="6">
              <div class="font-open-sans mb-2">
                <div class="font-weight-600">
                  Password
                </div>
                <div class="bank-search-input">
                  <b-input-group class="mt-1">
                    <b-form-input
                      id="password"
                      v-model="password"
                      v-validate="{
                        min: 6,
                        password: true,
                      }"
                      name="password"
                      type="password"
                      :class="
                        flag === 'edit'
                          ? [style['input-field'], 'border-0 disabled-fee']
                          : ''
                      "
                      :disabled="flag === 'edit' ? true : false"
                      autocomplete="off"
                    />
                    <template
                      v-if="flag === 'edit'"
                      #append
                    >
                      <b-input-group-text class="border-0 h-100">
                        <span :class="style['change-text']"> Change </span>
                      </b-input-group-text>
                    </template>
                  </b-input-group>
                  <span
                    id="input-1-live-agree_process_personal_data"
                    class="valdiation_error"
                  >
                    {{ veeErrors.first("password") }}
                  </span>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          v-if="account_type === '2'"
          md="6"
        >
          <div :class="['font-open-sans mb-2']">
            <div class="font-weight-600">
              About you
            </div>
            <div class="bank-search-input">
              <b-input-group class="mt-1">
                <b-form-textarea
                  id="about"
                  v-model="about_you"
                  name="about"
                  placeholder="E.g. Singer, Movie Director, and American Actress"
                  class="no-resize"
                  rows="4"
                  :class="
                    flag === 'edit' ? 'border-0 pb-4 disabled-fee' : 'pb-4'
                  "
                  :disabled="flag === 'edit' ? true : false"
                  autocomplete="off"
                />
              </b-input-group>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
          <div class="font-open-sans mb-2">
            <div class="font-weight-600">
              Birthdate
            </div>
            <div class="bank-search-input">
              <b-input-group
                :class="flag === 'edit' ? 'border-0 disabled-fee' : ''"
              >
                <datepicker
                  id="birthdate"
                  v-model="birthdate"
                  v-validate="{ required: true }"
                  placeholder="Select Birthdate"
                  :state="validateState('birthdate')"
                  aria-describedby="input-1-live-birthdate"
                  data-vv-as="Birthdate"
                  name="birthdate"
                  format="MM/dd/yyyy"
                  :disabledDates="disabledDates"
                  :bootstrap-styling="true"
                  :calendar-button="true"
                  :clear-button="true"
                  calendar-button-icon="fa fa-calendar"
                  clear-button-icon="fa fa-times"
                  :class="
                    flag === 'edit'
                      ? 'border-0 bg-transparent disabled-fee border shadow-none fab-birthdate'
                      : 'pb-4'
                  "
                  :disabled="flag === 'edit' ? true : false"
                />
              </b-input-group>
              <span
                id="input-1-live-agree_process_personal_data"
                class="valdiation_error"
              >
                {{ veeErrors.first("birthdate") }}
              </span>
            </div>
          </div>
        </b-col>
        <b-col md="3">
          <div class="font-open-sans mb-2">
            <div class="font-weight-600">
              Username
            </div>
            <div class="bank-search-input">
              <b-input-group class="mt-1">
                <b-form-input
                  id="username"
                  v-model="username"
                  v-validate="{ required: true, min: 3 }"
                  :state="validateState('username')"
                  name="username"
                  type="text"
                  :class="
                    flag === 'edit'
                      ? [style['input-field'], 'border-0 disabled-fee']
                      : ''
                  "
                  :disabled="flag === 'edit' ? true : false"
                  autocomplete="off"
                  maxlength="50"
                  @keyup="checkUsername"
                />
              </b-input-group>
              <span
                id="input-1-live-username"
                class="valdiation_error"
              >
                {{ veeErrors.first("username") }}
              </span>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
          <div class="font-open-sans mb-2">
            <div class="font-weight-600">
              Category
            </div>
            <div class="bank-search-input">
              <b-input-group class="mt-1">
                <Select2
                  id="category"
                  v-model="category"
                  v-validate="{ required: true }"
                  name="category"
                  :options="category_list"
                  :state="validateState('category')"
                  :class="
                    flag === 'edit'
                      ? [
                        style['cat-input-field'],
                        style['input-field'],
                        style['cat-input-field-disabled'],
                        'form-control w-100 border-0 py-2 w-100',
                      ]
                      : [
                        style['cat-input-field'],
                        style['input-field'],
                        'form-control w-100 border border-secondary py-2 w-100',
                      ]
                  "
                  :disabled="flag === 'edit' ? true : false"
                  :settings="{
                    settingOption: category,
                    settingOption: category,
                  }"
                  placeholder="Select Category"
                  @change="getSubCategoryList()"
                  @select="getSubCategoryList()"
                />
              </b-input-group>
              <span
                id="input-1-live-category"
                class="valdiation_error"
              >
                {{ veeErrors.first("category") }}
              </span>
            </div>
          </div>
        </b-col>
        <b-col md="3">
          <div class="font-open-sans mb-2">
            <div class="font-weight-600">
              Timezone
            </div>
            <div class="bank-search-input">
              <b-input-group class="mt-1">
                <Select2
                  id="timezone"
                  v-model="timezone"
                  v-validate="{ required: true }"
                  name="timezone"
                  :options="timezone_list"
                  :state="validateState('timezone')"
                  :class="
                    flag === 'edit'
                      ? [
                        style['input-field'],
                        'form-control w-100 border-0 disabled-fee py-2 w-100',
                      ]
                      : [
                        style['input-field'],
                        'form-control w-100 border border-secondary py-2 w-100',
                      ]
                  "
                  :disabled="flag === 'edit' ? true : false"
                  placeholder="Select Timezone"
                  @change="setTimezone"
                />
              </b-input-group>
              <span
                id="input-1-live-timezone"
                class="valdiation_error"
              >
                {{ veeErrors.first("timezone") }}
              </span>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <div class="font-open-sans mb-2">
            <div class="font-weight-600">
              Sub Category
            </div>
            <div class="bank-search-input">
              <b-input-group class="mt-1">
                <Select2
                  id="sub_category"
                  v-model="sub_category"
                  v-validate="{ required: true }"
                  name="sub_category"
                  :options="sub_category_list"
                  :settings="{ multiple: true }"
                  :class="
                    flag === 'edit'
                      ? [
                        style['cat-input-field'],
                        style['cat-input-field-disabled'],
                        'form-control w-100 border-0 disabled-fee py-2 w-100',
                      ]
                      : [
                        style['cat-input-field'],
                        'form-control w-100 border border-secondary py-2 w-100',
                      ]
                  "
                  :disabled="flag === 'edit' ? true : false"
                  placeholder="Select Sub Category"
                />
              </b-input-group>
              <span
                id="input-1-live-sub_category"
                class="valdiation_error"
              >
                {{ veeErrors.first("sub_category") }}
              </span>
            </div>
          </div>
        </b-col>
      </b-row>
      <account
        class="mt-5"
        :text="in_app_notification"
        :email="email_notification"
        @handleNotification="handleNotification"
      />
      <interactions
        v-if="callinteractions === true"
        :videoCallCharge="video_call_charge"
        :exclusivePostCharge="exclusive_post_charge"
        :liveStreamigCharge="live_streamig_charge"
        class="mt-5"
      />
    </b-container>
  </div>
</template>

<script>
import style from "../styles/settings.module.scss";
import Account from "../components/Settings/Account.vue";
import Interactions from "../components/Settings/Transactions/Interactions.vue";
import { Validator } from "vee-validate";
import { API_BASE_URL } from "../config/config";
import { APIUrlList } from "../js/api_url";
import { _sendResponse } from "../js/common-function";
import axios from "axios";
import GabSetting from "../components/Settings/GabSettings/Setting";
import LoadingSpinner from "../components/Common/LoadingSpinner";
import Datepicker from "vuejs-datepicker";
import { setTimezoneOptions } from "../api/meeting";
import { getCategory } from "../api/register";

Validator.extend("password", {
    getMessage:
    "Password should have at least 1 lowercase,1 uppercase,1 number and 1 symbol.",
    validate: (value) =>
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/.test(value),
});
export default {
    name: "Settings",
    components: { Account, Interactions, GabSetting, LoadingSpinner, Datepicker },
    data() {
        return {
            style,
            flag: "edit",
            disabledDates: {
                from: new Date(Date.now() - 8640000),
            },
            name: "",
            surname: "",
            email: "",
            password: "",
            about_you: "",
            profile_pic_url: "",
            username: "",
            account_type: localStorage.getItem("account-type").toString(),
            accountDetails: {},
            loading: true,
            callinteractions: false,
            video_call_charge: 0,
            exclusive_post_charge: 0,
            live_streamig_charge: 0,
            email_notification: "",
            in_app_notification: "",
            birthdate: "",
            user_address: {},
            timezone: "",
            timezone_list: [],
            timezone_list_offset: [],
            category_list: [],
            sub_category_list: [],
            sub_category: "",
            category: "",
            is_update_profile: 0,
        };
    },
    async created() {
        axios
            .get(API_BASE_URL + APIUrlList.PROFILEDETAILS, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("user-token"),
                },
            })
            .then((response) => {
                this.accountDetails = response.data.data;
                this.name = response.data.data.name;
                this.surname = response.data.data.surname;
                this.email = response.data.data.email;
                this.about_you = response.data.data.about_you;
                this.username = response.data.data.username;
                this.timezone = response.data.data.user_address
                    ? response.data.data.user_address.timezone_id
                    : "";
                this.profile_pic_url =
          response.data.data.connected_via === 2
              ? response.data.data.profile_pic
              : response.data.data.profile_pic_url;
                this.video_call_charge = this.accountDetails.video_call_fee;
                this.exclusive_post_charge = this.accountDetails.exclusive_post_fee;
                this.live_streamig_charge = this.accountDetails.live_streamig_fee;
                this.email_notification = this.accountDetails.email_notification;
                this.in_app_notification = this.accountDetails.in_app_notification;
                this.birthdate = this.accountDetails.birthdate;
                this.user_address = this.accountDetails.user_address;
                this.callinteractions = true;
                this.loading = false;
                this.category =
          response.data.data.interested_category &&
          response.data.data.interested_category[0]
              ? response.data.data.interested_category[0].cat_id
              : "";
                let subCatList = this.getSubCategoryList();
                if (subCatList && subCatList.length > 0) {
                    this.sub_category_list = subCatList;
                }

                if (response.data.data.interested_category) {
                    let subCat = response.data.data.interested_category.map((cat) => {
                        return cat.sub_cat_id;
                    });
                    this.sub_category = subCat;
                }
                this.is_update_profile = response.data.data.is_profile_updated;
                if (this.is_update_profile == 0) {
                    _sendResponse("warning", "Please complete your profile first");
                }
            });

        let timezones = await setTimezoneOptions();
        this.timezone_list = timezones.timezones2;
        this.timezone_list_offset = timezones.offset;
        let categoryList = await getCategory();
        if (categoryList.length > 0) {
            this.category_list = categoryList;
        }
    },
    methods: {
        validateState(ref) {
            if (
                this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
            ) {
                return !this.veeErrors.has(ref);
            }
            return null;
        },
        handleEdit() {
            this.flag = "save";
        },
        handleNotification(value) {
            this.email_notification = value.email;
            this.in_app_notification = value.text;
            this.handleSave(this.email_notification, this.in_app_notification);
        },
        handleSave(email, text) {
            this.$validator.validateAll().then((result) => {
                if (!result) {
                    // this.isLoading = false;
                    return;
                }
                let newList = [];
                if (this.sub_category && this.sub_category != "") {
                    this.sub_category.map((value) => {
                        newList.push(parseInt(value));
                    });
                }

                let stringToPass = this.sub_category
                    ? JSON.stringify(newList)
                        .replace("[", "")
                        .replace("]", "")
                    : "";
                this.flag = "edit";
                let formData = {};
                this.is_update_profile = 1;
                formData.name = this.name;
                formData.surname = this.surname;
                formData.email = this.email;
                formData.username = this.username;
                this.password !== "" && this.password !== null
                    ? (formData.password = this.password)
                    : null;
                formData.email_notification = email;
                formData.in_app_notification = text;
                formData.about_you = this.about_you;
                formData.birthdate = this.birthdate;
                formData.timezone_id = this.timezone;
                formData.sub_category = stringToPass;
                formData.category = this.category;
                formData.is_update_profile = this.is_update_profile;
                this.loading = true;
                axios
                    .post(API_BASE_URL + APIUrlList.UPDATEPROFILE, formData, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("user-token"),
                        },
                    })
                    .then((response) => {
                        this.loading = false;
                        if (response.data.status == false) {
                            _sendResponse("error", response.data.message);
                        } else {
                            _sendResponse("success", response.data.message);
                        }

                        this.name = response.data.data.name;
                        this.surname = response.data.data.surname;
                        this.email = response.data.data.email;
                        this.about_you = response.data.data.about_you;
                        this.username = response.data.data.username;
                        this.timezone_id = response.data.data.user_address
                            ? response.data.data.user_address.timezone_id
                            : "";

                        localStorage.setItem("username", response.data.data.username);
                        localStorage.setItem(
                            "fullname",
                            response.data.data.name + " " + response.data.data.surname
                        );
                        localStorage.setItem("isProfileUpdated", this.is_update_profile);
                        setTimeout(function() {
                            window.location.reload();
                        }, 1000);
                    });
            });
        },
        async getSubCategoryList() {
            this.sub_category_list = [];
            let subCatList = await getCategory(this.category);
            if (subCatList && subCatList.length > 0) {
                this.sub_category_list = subCatList;
            }
        },
        checkUsername() {
            this.username = this.username
                .toLowerCase()
                .replace(/[^\w\s]/gi, "")
                .split(" ")
                .join("");
        },
        setTimezone() {
            this.timezone_offset = this.timezone_list_offset[this.timezone];
        },
    },
};
</script>
