<template>
  <section>
    <OrderDetails
      v-if="order_id > 0"
      :show="show"
      :orderId="order_id"
      :totalPrice="total_price"
      :orderNo="order_no"
      @onClose="handleClose"
    />
    <LoadingSpinner v-if="loadingPayments" />
    <b-row class="mb-4">
      <b-col cols="12">
        <h3 class="font-weight-bold pb-2 border-bottom">
          Payments
        </h3>
        <template v-if="paymentItems.length">
          <b-table
            :items="paymentItems"
            :responsive="true"
            :fields="fields"
            :class="['font-weight-light font-open-sans', style['payments-table']]"
          >
            <template #cell(item)="data">
              <div
                v-if="data.item.type === 3"
                variant="primary"
                pill
                :class="[style['cursor-pointer'],'text-uppercase']"
                @click="showOrderDetails(data.item.id,data.item.totalPrice,data.item.orderNo)"
              >
                {{ data.item.item }}
              </div>
              <div v-else>
                {{ data.item.item }}
              </div>
            </template>
          </b-table>
          <div
            v-if="paymentPagination > 0"
            :class="['d-flex float-right', style['payment-pagination-wrap']]"
          >
            <b-button
              variant="dark"
              class="mr-2 mb-2 shadow-none rounded-0 px-3"
              @click="handlePrevious"
            >
              <!-- eslint-disable-next-line vue/no-parsing-error -->
              <span>&lt;</span>
            </b-button>
            <div :class="['d-flex float-right', style['payment-page-numbers']]">
              <p
                v-for="(page) in total_pages"
                :key="page"
                :class="[pageNumber === page ? style['pagination-buttons'] : '', 'px-2 mr-2 mt-2']"
              >
                {{ page }}
              </p>
            </div>
            <b-button
              variant="dark"
              class="mr-2 mb-2 shadow-none rounded-0 px-3"
              @click="handleNextPage"
            >
              >
            </b-button>
          </div>
        </template>
        <template v-else>
          <div class="font-weight-bold text-center h4">
            No records found
          </div>
        </template>
      </b-col>
    </b-row>
  </section>
</template>
<script>
import style from "../../../styles/settings.module.scss";
import LoadingSpinner from "../../Common/LoadingSpinner";
import { getGabPaymentsList } from '../../../api/settings';
import moment from 'moment-timezone';
import OrderDetails from "./OrderDetails";

const fields = [
    { key: 'item', label: 'Payment Details' },
    { key: 'paymentDate', label: 'Payment Date' },
    { key: 'totalPrice', label: 'Total Price' },
    { key: 'status', label: 'Status', tdClass: "text-capitalize"},
    { key: 'pay', label: '' },
];
export default ({
    components:{LoadingSpinner, OrderDetails},
    data() {
        return {
            style,
            fields,
            paymentItems: [],
            currentPage: 1,
            total_pages:[],
            pageNumber:1,
            last_page:1,
            loadingPayments:false,
            paymentPagination:false,
            show: false,
            showModal: false,
            order_id:0,
            total_price: 0
        };
    },
    async created(){
        let payments = await getGabPaymentsList('1');
        if (payments && Object.keys(payments).length > 0){
            this.currentPage = payments.current_page;
            this.perPage = payments.perPage;
            for (var i=0; i<payments.last_page; i++){
                this.total_pages.push(i+1);
            }
            this.last_page = payments.last_page;
            if (this.last_page > 1){
                this.paymentPagination = true;
            }
            payments.data.map(value => {
                let ObjToPush = {
                    id: value.id,
                    type: value.type,
                    item: value.label,
                    paymentDate: value.date !== null ? moment(value.date).format('MMM DD, YYYY') : '',
                    totalPrice: value.amount !== null ? '$'+value.amount.toFixed(2) : '$'+0,
                    status: value.payment_status == 'COMPLETED' ? 'Paid' : 'Due',
                    orderNo: value.order_no
                };
                this.paymentItems.push(ObjToPush);
            });
        }
    },
    methods:{
        async handleNextPage(){
            if (this.pageNumber !== this.last_page){
                this.loadingPayments = true;
                this.paymentItems = [];
                this.pageNumber = this.pageNumber + 1;
                let payments = await getGabPaymentsList(this.pageNumber);
                if (payments && Object.keys(payments).length > 0){
                    payments.data.map(value => {
                        let ObjToPush = {
                            id: value.id,
                            type: value.type,
                            item: value.label,
                            paymentDate: value.date !== null ? moment(value.date).format('MMM DD, YYYY') : '',
                            totalPrice: value.amount !== null ? '$'+value.amount.toFixed(2) : '$'+0,
                            status: value.payment_status == 'COMPLETED' ? 'Paid' : 'Due',
                            orderNo: value.order_no
                        };
                        this.paymentItems.push(ObjToPush);
                    });
                    this.loadingPayments = false;
                } else {
                    this.loadingPayments = false;
                }
            }
        },
        async handlePrevious(){
            if (this.pageNumber > 1){
                this.loadingPayments = true;
                this.pageNumber = this.pageNumber - 1;
                this.paymentItems = [];
                let payments = await getGabPaymentsList(this.pageNumber);
                if (payments && Object.keys(payments).length > 0){
                    payments.data.map(value => {
                        let ObjToPush = {
                            id: value.id,
                            type: value.type,
                            item: value.label,
                            paymentDate: value.date !== null ? moment(value.date).format('MMM DD, YYYY') : '',
                            totalPrice: value.amount !== null ? '$'+value.amount.toFixed(2) : '$'+0,
                            status: value.payment_status == 'COMPLETED' ? 'Paid' : 'Due',
                            orderNo: value.order_no
                        };
                        this.paymentItems.push(ObjToPush);
                    });
                    this.loadingPayments = false;
                } else {
                    this.loadingPayments = false;
                }
            }
        },
        // myRowClickHandler(record, index) {
        //     this.show = true;
        //     console.log(index);
        //     // 'record' will be the row data from items
        //     // `index` will be the visible row number (available in the v-model 'shownItems')
        //     console.log(record); // This will be the item data for the row
        //     console.log(record.type);
        // },
        showOrderDetails(orderId,totalPrice,orderNo) {
            this.show = true;
            this.order_id = orderId;
            this.total_price = totalPrice;
            this.order_no = orderNo;
        },
        handleClose() {
            this.show = false;
        },
    }
});
</script>
