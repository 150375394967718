var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('LoadingSpinner'):_vm._e(),(_vm.account_type === '1')?_c('gab-setting',{attrs:{"flag":_vm.flag,"profilePicUrl":_vm.profile_pic_url,"accountDetails":_vm.accountDetails}}):_c('b-container',{class:[_vm.style['settings-wrap'], 'py-md-4 mb-5']},[_c('h2',{staticClass:"font-weight-bold mb-md-5 mb-4 mt-5 display-4"},[_vm._v(" Settings ")]),_c('h3',{class:[_vm.style['general-text'], 'font-weight-bold', 'mb-md-5', 'mb-2']},[_vm._v(" General ")]),_c('div',{staticClass:"h3 font-weight-bold"},[_c('p',{staticClass:"header-title pb-3 border-bottom"},[_vm._v(" Personal "),(_vm.flag === 'edit')?_c('img',{attrs:{"src":require("../assets/edit-icon.png"),"alt":"edit"},on:{"click":_vm.handleEdit}}):_vm._e(),(_vm.flag === 'save')?_c('svg',{attrs:{"width":"32","height":"32","viewBox":"0 0 32 32","fill":"none","xmlns":"http://www.w3.org/2000/svg"},on:{"click":_vm.handleSave}},[_c('circle',{attrs:{"cx":"16","cy":"16","r":"16","fill":"#4A4A4A"}}),_c('path',{attrs:{"d":"M19.6667 8H9.66667C8.74167 8 8 8.75 8 9.66667V21.3333C8 22.25 8.74167 23 9.66667 23H21.3333C22.25 23 23 22.25 23 21.3333V11.3333L19.6667 8ZM15.5 21.3333C14.1167 21.3333 13 20.2167 13 18.8333C13 17.45 14.1167 16.3333 15.5 16.3333C16.8833 16.3333 18 17.45 18 18.8333C18 20.2167 16.8833 21.3333 15.5 21.3333ZM18 13H9.66667V9.66667H18V13Z","fill":"white"}})]):_vm._e()])]),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('div',{staticClass:"font-open-sans mb-2"},[_c('div',{staticClass:"font-weight-600"},[_vm._v(" Name ")]),_c('div',{staticClass:"bank-search-input"},[_c('b-input-group',{staticClass:"mt-1"},[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],class:_vm.flag === 'edit'
                        ? [_vm.style['input-field'], 'border-0 disabled-fee']
                        : '',attrs:{"id":"name","state":_vm.validateState('name'),"name":"name","type":"text","disabled":_vm.flag === 'edit' ? true : false,"autocomplete":"off"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('span',{staticClass:"valdiation_error",attrs:{"id":"input-1-live-agree_process_personal_data"}},[_vm._v(" "+_vm._s(_vm.veeErrors.first("name"))+" ")])],1)])]),_c('b-col',{attrs:{"md":"6"}},[_c('div',{staticClass:"font-open-sans mb-2"},[_c('div',{staticClass:"font-weight-600"},[_vm._v(" Last Name ")]),_c('div',{staticClass:"bank-search-input"},[_c('b-input-group',{staticClass:"mt-1"},[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],class:_vm.flag === 'edit'
                        ? [_vm.style['input-field'], 'border-0 disabled-fee']
                        : '',attrs:{"id":"surname","state":_vm.validateState('surname'),"data-vv-as":"Last Name","name":"surname","type":"text","disabled":_vm.flag === 'edit' ? true : false,"autocomplete":"off"},model:{value:(_vm.surname),callback:function ($$v) {_vm.surname=$$v},expression:"surname"}})],1),_c('span',{staticClass:"valdiation_error",attrs:{"id":"input-1-live-agree_process_personal_data"}},[_vm._v(" "+_vm._s(_vm.veeErrors.first("surname"))+" ")])],1)])]),_c('b-col',{attrs:{"md":"6"}},[_c('div',{staticClass:"font-open-sans mb-2"},[_c('div',{staticClass:"font-weight-600"},[_vm._v(" Email ")]),_c('div',{staticClass:"bank-search-input"},[_c('b-input-group',{staticClass:"mt-1"},[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],class:[_vm.style['input-field'], 'border-0 disabled-fee'],attrs:{"id":"email","state":_vm.validateState('email'),"name":"email","type":"email","disabled":"","autocomplete":"off"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('span',{staticClass:"valdiation_error",attrs:{"id":"input-1-live-agree_process_personal_data"}},[_vm._v(" "+_vm._s(_vm.veeErrors.first("email"))+" ")])],1)])]),_c('b-col',{attrs:{"md":"6"}},[_c('div',{staticClass:"font-open-sans mb-2"},[_c('div',{staticClass:"font-weight-600"},[_vm._v(" Password ")]),_c('div',{staticClass:"bank-search-input"},[_c('b-input-group',{staticClass:"mt-1",scopedSlots:_vm._u([(_vm.flag === 'edit')?{key:"append",fn:function(){return [_c('b-input-group-text',{staticClass:"border-0 h-100"},[_c('span',{class:_vm.style['change-text']},[_vm._v(" Change ")])])]},proxy:true}:null],null,true)},[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({
                      min: 6,
                      password: true,
                    }),expression:"{\n                      min: 6,\n                      password: true,\n                    }"}],class:_vm.flag === 'edit'
                        ? [_vm.style['input-field'], 'border-0 disabled-fee']
                        : '',attrs:{"id":"password","name":"password","type":"password","disabled":_vm.flag === 'edit' ? true : false,"autocomplete":"off"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('span',{staticClass:"valdiation_error",attrs:{"id":"input-1-live-agree_process_personal_data"}},[_vm._v(" "+_vm._s(_vm.veeErrors.first("password"))+" ")])],1)])])],1)],1),(_vm.account_type === '2')?_c('b-col',{attrs:{"md":"6"}},[_c('div',{class:['font-open-sans mb-2']},[_c('div',{staticClass:"font-weight-600"},[_vm._v(" About you ")]),_c('div',{staticClass:"bank-search-input"},[_c('b-input-group',{staticClass:"mt-1"},[_c('b-form-textarea',{staticClass:"no-resize",class:_vm.flag === 'edit' ? 'border-0 pb-4 disabled-fee' : 'pb-4',attrs:{"id":"about","name":"about","placeholder":"E.g. Singer, Movie Director, and American Actress","rows":"4","disabled":_vm.flag === 'edit' ? true : false,"autocomplete":"off"},model:{value:(_vm.about_you),callback:function ($$v) {_vm.about_you=$$v},expression:"about_you"}})],1)],1)])]):_vm._e()],1),_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('div',{staticClass:"font-open-sans mb-2"},[_c('div',{staticClass:"font-weight-600"},[_vm._v(" Birthdate ")]),_c('div',{staticClass:"bank-search-input"},[_c('b-input-group',{class:_vm.flag === 'edit' ? 'border-0 disabled-fee' : ''},[_c('datepicker',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],class:_vm.flag === 'edit'
                    ? 'border-0 bg-transparent disabled-fee border shadow-none fab-birthdate'
                    : 'pb-4',attrs:{"id":"birthdate","placeholder":"Select Birthdate","state":_vm.validateState('birthdate'),"aria-describedby":"input-1-live-birthdate","data-vv-as":"Birthdate","name":"birthdate","format":"MM/dd/yyyy","disabledDates":_vm.disabledDates,"bootstrap-styling":true,"calendar-button":true,"clear-button":true,"calendar-button-icon":"fa fa-calendar","clear-button-icon":"fa fa-times","disabled":_vm.flag === 'edit' ? true : false},model:{value:(_vm.birthdate),callback:function ($$v) {_vm.birthdate=$$v},expression:"birthdate"}})],1),_c('span',{staticClass:"valdiation_error",attrs:{"id":"input-1-live-agree_process_personal_data"}},[_vm._v(" "+_vm._s(_vm.veeErrors.first("birthdate"))+" ")])],1)])]),_c('b-col',{attrs:{"md":"3"}},[_c('div',{staticClass:"font-open-sans mb-2"},[_c('div',{staticClass:"font-weight-600"},[_vm._v(" Username ")]),_c('div',{staticClass:"bank-search-input"},[_c('b-input-group',{staticClass:"mt-1"},[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true, min: 3 }),expression:"{ required: true, min: 3 }"}],class:_vm.flag === 'edit'
                    ? [_vm.style['input-field'], 'border-0 disabled-fee']
                    : '',attrs:{"id":"username","state":_vm.validateState('username'),"name":"username","type":"text","disabled":_vm.flag === 'edit' ? true : false,"autocomplete":"off","maxlength":"50"},on:{"keyup":_vm.checkUsername},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})],1),_c('span',{staticClass:"valdiation_error",attrs:{"id":"input-1-live-username"}},[_vm._v(" "+_vm._s(_vm.veeErrors.first("username"))+" ")])],1)])])],1),_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('div',{staticClass:"font-open-sans mb-2"},[_c('div',{staticClass:"font-weight-600"},[_vm._v(" Category ")]),_c('div',{staticClass:"bank-search-input"},[_c('b-input-group',{staticClass:"mt-1"},[_c('Select2',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],class:_vm.flag === 'edit'
                    ? [
                      _vm.style['cat-input-field'],
                      _vm.style['input-field'],
                      _vm.style['cat-input-field-disabled'],
                      'form-control w-100 border-0 py-2 w-100' ]
                    : [
                      _vm.style['cat-input-field'],
                      _vm.style['input-field'],
                      'form-control w-100 border border-secondary py-2 w-100' ],attrs:{"id":"category","name":"category","options":_vm.category_list,"state":_vm.validateState('category'),"disabled":_vm.flag === 'edit' ? true : false,"settings":{
                  settingOption: _vm.category,
                  settingOption: _vm.category,
                },"placeholder":"Select Category"},on:{"change":function($event){return _vm.getSubCategoryList()},"select":function($event){return _vm.getSubCategoryList()}},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}})],1),_c('span',{staticClass:"valdiation_error",attrs:{"id":"input-1-live-category"}},[_vm._v(" "+_vm._s(_vm.veeErrors.first("category"))+" ")])],1)])]),_c('b-col',{attrs:{"md":"3"}},[_c('div',{staticClass:"font-open-sans mb-2"},[_c('div',{staticClass:"font-weight-600"},[_vm._v(" Timezone ")]),_c('div',{staticClass:"bank-search-input"},[_c('b-input-group',{staticClass:"mt-1"},[_c('Select2',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],class:_vm.flag === 'edit'
                    ? [
                      _vm.style['input-field'],
                      'form-control w-100 border-0 disabled-fee py-2 w-100' ]
                    : [
                      _vm.style['input-field'],
                      'form-control w-100 border border-secondary py-2 w-100' ],attrs:{"id":"timezone","name":"timezone","options":_vm.timezone_list,"state":_vm.validateState('timezone'),"disabled":_vm.flag === 'edit' ? true : false,"placeholder":"Select Timezone"},on:{"change":_vm.setTimezone},model:{value:(_vm.timezone),callback:function ($$v) {_vm.timezone=$$v},expression:"timezone"}})],1),_c('span',{staticClass:"valdiation_error",attrs:{"id":"input-1-live-timezone"}},[_vm._v(" "+_vm._s(_vm.veeErrors.first("timezone"))+" ")])],1)])])],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('div',{staticClass:"font-open-sans mb-2"},[_c('div',{staticClass:"font-weight-600"},[_vm._v(" Sub Category ")]),_c('div',{staticClass:"bank-search-input"},[_c('b-input-group',{staticClass:"mt-1"},[_c('Select2',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],class:_vm.flag === 'edit'
                    ? [
                      _vm.style['cat-input-field'],
                      _vm.style['cat-input-field-disabled'],
                      'form-control w-100 border-0 disabled-fee py-2 w-100' ]
                    : [
                      _vm.style['cat-input-field'],
                      'form-control w-100 border border-secondary py-2 w-100' ],attrs:{"id":"sub_category","name":"sub_category","options":_vm.sub_category_list,"settings":{ multiple: true },"disabled":_vm.flag === 'edit' ? true : false,"placeholder":"Select Sub Category"},model:{value:(_vm.sub_category),callback:function ($$v) {_vm.sub_category=$$v},expression:"sub_category"}})],1),_c('span',{staticClass:"valdiation_error",attrs:{"id":"input-1-live-sub_category"}},[_vm._v(" "+_vm._s(_vm.veeErrors.first("sub_category"))+" ")])],1)])])],1),_c('account',{staticClass:"mt-5",attrs:{"text":_vm.in_app_notification,"email":_vm.email_notification},on:{"handleNotification":_vm.handleNotification}}),(_vm.callinteractions === true)?_c('interactions',{staticClass:"mt-5",attrs:{"videoCallCharge":_vm.video_call_charge,"exclusivePostCharge":_vm.exclusive_post_charge,"liveStreamigCharge":_vm.live_streamig_charge}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }