<template>
  <section style="overflow-x: auto">
    <LoadingSpinner v-if="loadingStore" />
    <div class="h3 font-weight-bold">
      <p class="header-title pb-3 border-bottom">
        Store payments
      </p>
    </div>
    <template v-if="items.length">
      <b-table
        striped
        hover
        :items="items"
        :borderless="true"
        table-variant="light"
        :class="[style['payment-table'],'font-open-sans font-weight-light']"
        :per-page="perPage"
        :current-page="currentPage"
      />
      <div
        v-if="storePagination > 0"
        :class="['d-flex float-right', style['payment-pagination-wrap']]"
      >
        <b-button
          variant="dark"
          class="mr-2 mb-2 shadow-none rounded-0 px-3"
          @click="handlePrevious"
        >
          <!-- eslint-disable-next-line vue/no-parsing-error -->
          <span>&lt;</span>
        </b-button>
        <div :class="['d-flex float-right', style['payment-page-numbers']]">
          <p
            v-for="(page) in total_pages"
            :key="page"       
            :class="[pageNumber === page ? style['pagination-buttons'] : '', 'px-2 mr-2 mt-2']"
          >
            {{ page }}
          </p>
        </div>
        <b-button
          variant="dark"
          class="mr-2 mb-2 shadow-none rounded-0 px-3"
          @click="handleNextPage"
        >
          >
        </b-button>
      </div>
    </template>
    <template v-else>
      <div class="font-weight-bold text-center h4">
        No records found
      </div>
    </template>
  </section>
</template>

<script>
import style from '../../../styles/settings.module.scss';
import { getStorePaymentsList } from '../../../api/settings';
import moment from 'moment';
import LoadingSpinner from "../../Common/LoadingSpinner";

export default {
    components:{LoadingSpinner},
    data() {
        return {
            style,
            items: [],
            currentPage: 1,
            perPage:5,
            total_pages:[],
            pageNumber:1,
            last_page:1,
            loadingStore: false,
            storePagination:false
        };
    },
    async created(){
        let payments = await getStorePaymentsList('1');
        if (payments && Object.keys(payments).length > 0){
            this.currentPage = payments.current_page;
            this.perPage = payments.perPage;
            this.last_page = payments.last_page;
            for (var i=0; i<payments.last_page; i++){
                this.total_pages.push(i+1);
            }
            if (this.last_page > 1){
                this.storePagination = true;
            }
            payments.data.map(value => {
                let ObjToPush = {
                    item: value.name !== null ? value.name : '-',
                    paymentDate: value.created_at !== null ? moment(value.created_at).format('MMM DD, YYYY') : '',
                    totalPrice: value.price !== null ? '$'+value.price.toFixed(2) : '$'+0,
                    gab: value.gab_name !== null ? value.gab_name : '',
                    shippingDetails:'-',
                    status:'Paid',
                };
                this.items.push(ObjToPush);
            });
        }
    },
    methods:{
        async handleNextPage(){
            if (this.pageNumber !== this.last_page){
                this.loadingStore = true;
                this.items = [];
                this.pageNumber = this.pageNumber + 1;
                let payments = await getStorePaymentsList(this.pageNumber);
                if (payments && Object.keys(payments).length > 0){
                    payments.data.map(value => {
                        let ObjToPush = {
                            item: value.name !== null ? value.name : '-',
                            paymentDate: value.created_at !== null ? moment(value.created_at).format('MMM DD, YYYY') : '',
                            totalPrice: value.price !== null ? '$'+value.price.toFixed(2) : '$'+0,
                            gab: value.gab_name !== null ? value.gab_name : '',
                            shippingDetails:'-',
                            status:'Paid',
                        };
                        this.items.push(ObjToPush);
                    });
                    this.loadingStore = false;
                } else {
                    this.loadingStore = false;
                }
            }
        },
        async handlePrevious(){
            if (this.pageNumber > 1){
                this.loadingStore = true;
                this.pageNumber = this.pageNumber - 1;
                this.items = [];
                let payments = await getStorePaymentsList(this.pageNumber);
                if (payments && Object.keys(payments).length > 0){
                    payments.data.map(value => {
                        let ObjToPush = {
                            item: value.name !== null ? value.name : '-',
                            paymentDate: value.created_at !== null ? moment(value.created_at).format('MMM DD, YYYY') : '',
                            totalPrice: value.price !== null ? '$'+value.price.toFixed(2) : '$'+0,
                            gab: value.gab_name !== null ? value.gab_name : '',
                            shippingDetails:'-',
                            status:'Paid',
                        };
                        this.items.push(ObjToPush);
                    });
                    this.loadingStore = false;
                } else {
                    this.loadingStore = false;
                }
            }
        }
    }
};
</script>