<template>
  <section>
    <b-row class="my-md-5 my-4">
      <b-col cols="12">
        <h3 class="font-weight-bold mb-md-5 mb-2 pb-2 border-bottom">
          Account
        </h3>
      </b-col>
      <b-col 
        cols="12" 
        class="font-open-sans font-weight-light mb-2"
      >
        <b-row>
          <b-col 
            cols="3" 
            md="2"
          >
            Verification
          </b-col>
          <b-col 
            cols="9"
            md="10"
            :class="['text-16 text-decoration-underline',style['account-text']]"
          >
            Verify
          </b-col>
        </b-row>
      </b-col>
      <b-col 
        cols="12"
        class="font-open-sans font-weight-light mb-2"
      >
        <b-row>
          <b-col 
            cols="3" 
            md="2"
          >
            Facebook
          </b-col>
          <b-col 
            cols="9"
            md="10"
            :class="['text-16 text-decoration-underline',style['account-text']]"
          >
            Connect with Facebook
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="my-md-5 my-4">
      <b-col cols="12">
        <h3 class="font-weight-bold mb-md-5 mb-2 pb-2 border-bottom">
          Notifications
        </h3>
      </b-col>
      <b-col 
        cols="12" 
        class="font-open-sans font-weight-light mb-2"
      >
        <b-row>
          <b-col 
            cols="3" 
            md="1"
          >
            <b-form-checkbox
              id="email_notification"
              v-model="email_notification"
              name="email_notification"
              value="1"
              unchecked-value="0"
              class="font-open-sans theme-checkbox custom-checkbox-success mb-4"
              @change="handleNotifications"
            />
          </b-col>
          <b-col 
            cols="9"
            md="10"
            :class="['text-16 pl-0 ',style['account-text']]"
          >
            Email Notification
          </b-col>
        </b-row>
      </b-col>
      <b-col 
        cols="12"
        class="font-open-sans font-weight-light mb-2"
      >
        <b-row>
          <b-col 
            cols="3" 
            md="1"
          >
            <b-form-checkbox
              id="in_app_notification"
              v-model="
                in_app_notification
              "
              name="in_app_notification"
              value="1"
              unchecked-value="0"
              class="font-open-sans theme-checkbox custom-checkbox-success mb-4"
              @change="handleNotifications"
            />
          </b-col>
          <b-col 
            cols="9"
            md="10"
            :class="['text-16 pl-0 ',style['account-text']]"
          >
            In-app Notification
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </section>
</template>
<script>
import style from "../../../styles/settings.module.scss";
export default ({
    props:{
        text:{
            type: [Number,String],
            default: 0
        },
        email:{
            type: [Number,String],
            default: 0
        },
    },
    data() {
        return {
            style,
            email_notification:this.email,
            in_app_notification: this.text,
        };
    },
    watch:{
        email:function(newVal){
            this.email_notification = newVal;
        },
        text:function(newVal){
            this.in_app_notification = newVal;
        }
    },
    methods:{
        handleNotifications(){
            let objToSend = {
                email: this.email_notification,
                text: this.in_app_notification,
            };

            this.$emit('handleNotifications', objToSend);
        }
    }
});
</script>
